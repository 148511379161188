import React, { Component } from "react";

import "./styles/index.scss";
import Testimony from "../../components/testimony";
import Navbar from "../../components/navbar";
import Hero from "../../components/hero";
import Separator from "../../components/separator";
import Slider from "react-slick";
import Know from "../../components/know";
import PlansModal from "../../components/plans-modal";
// import config from "../../config";

interface HomeProps {}

interface HomeState {}

class Home extends Component<HomeProps, HomeState> {
  render() {
    return (
      <>
        <Navbar />
        <Hero />

        <section className="estrutura">
          <div className="estrutura__media">
            <img
              className="estrutura__media_image"
              src="/images/estrutura-media-sketch.png"
              alt="estrutura sketch"
            />
          </div>

          <div className="estrutura__content">
            <div className="estrutura__content_graphic"></div>
            <h1 className="estrutura__content_title">
              UMA ESTRUTURA PARA SERVIR A{" "}
              <span className="highlighted">UMA VISÃO</span>
            </h1>
            <p className="estrutura__content_text">
              Como igreja nesta geração, nossa visão é atuar na última reforma
              protestante, formando agentes de transformação por meio do
              evangelho da graça de Deus e ter influência e atuação global com
              este ensino e estilo de vida.
            </p>
            <p className="estrutura__content_text">
              Para servir à essa visão, acreditamos que uma estrutura é
              fundamental e bem-vinda nos dias atuais.
            </p>
            <p className="estrutura__content_text">
              A estrutura nada mais é do que uma ferramenta facilitadora, tanto
              para promover o ambiente de adoração, comunhão, refúgio e
              propagação do evangelho, quanto para expandir as possibilidades de
              ensino, com cursos e condições para multiplicar as plataformas de
              ensino do evangelho da graça de Deus.
            </p>
            <p className="estrutura__content_text">
              Trata-se de servir às pessoas, provendo estrutura de apoio às
              atividades em prol da família espiritual e da comunidade que nos
              cerca.
            </p>
            <p className="estrutura__content_text">
              Trata-se de anunciar a sã doutrina, para que mais vidas sejam
              alcançadas, libertas e transformadas por Jesus por meio do
              evangelho da graça de Deus.
            </p>
            <p className="estrutura__content_text">
              São Paulo não é qualquer cidade.
            </p>
            <p className="estrutura__content_text">
              São Paulo traz relevância para o Brasil e para o mundo, assim como
              a sede da Cristo Vive - São Paulo trará relevância para a Palavra
              da graça em nosso país e nesta Terra.
            </p>
            <p className="estrutura__content_text">
              Expansão local, visando expansão global.
            </p>

            <div className="estrutura__white-bg"></div>
          </div>
        </section>
        <section className="depoimentos">
          <div className="depoimentos__separator">
            <Separator />
          </div>

          <div className="depoimentos__title">
            <h2>
              <span className="highlighted">Juntos </span>Somos mais fortes
            </h2>
          </div>

          <Slider
            centerMode={true}
            centerPadding="0px"
            infinite={true}
            slidesToShow={3}
            dots={false}
            arrows={false}
            speed={500}
            variableWidth={true}
            responsive={[
              {
                breakpoint: 920,
                settings: {
                  slidesToShow: 3,
                  centerPadding: "200px",
                },
              },
            ]}
          >
            <div
              data-bs-toggle="modal"
              data-bs-target="#video1"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-1.png"
                alt="Expectativas Sede"
              />

              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>

            <div
              data-bs-toggle="modal"
              data-bs-target="#video2"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-2.png"
                alt="Eu Pedia todo dia"
              />
              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>

            <div
              data-bs-toggle="modal"
              data-bs-target="#video3"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-3.png"
                alt="Escrevendo mais um capitulo"
              />
              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>

            <div
              data-bs-toggle="modal"
              data-bs-target="#video4"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-4.png"
                alt="Pisando na terra"
              />
              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>

            <div
              data-bs-toggle="modal"
              data-bs-target="#video5"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-5.png"
                alt="Juntos somos mais fortes"
              />
              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>

            <div
              data-bs-toggle="modal"
              data-bs-target="#video6"
              className="depoimentos__item"
            >
              <img
                className="depoimentos__item_image"
                src="/images/thumbs/sede-video-6.png"
                alt="Uma transformação não só dentro da igreja"
              />
              <img
                className="depoimentos__item_icon"
                src="/images/icons/play-icon.svg"
                alt="play"
              />
            </div>
          </Slider>
        </section>

        <Know />

        <div className="callout">
          <h1 className="callout__text">
            Vamos escrever essa história{" "}
            <span className="callout__text_highlighted">juntos?</span>
          </h1>
          <button
            data-bs-toggle="modal"
            data-bs-target="#plans-modal"
            className="callout__button"
          >
            Quero Doar
          </button>
        </div>
        <footer className="footer">2024 ® Todos os direitos reservados</footer>

        <Testimony videoId="iNJkIlvFzQY" title="video1" />
        <Testimony videoId="lySeShSb520" title="video2" />
        <Testimony videoId="pHaasqZ7dOA" title="video3" />
        <Testimony videoId="Bty81yMPNEI" title="video4" />
        <Testimony videoId="Qhdrvr48Yd8" title="video5" />
        <Testimony videoId="QTioZpLNBVo" title="video6" />
        <PlansModal />
      </>
    );
  }
}

export default Home;
