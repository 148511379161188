import React, { Component } from "react";
import "./styles/index.scss";
import ProgressBar from "@ramonak/react-progress-bar";

interface ProgressProps {}

interface ProgressState {
  completed: number;
}

class Progress extends Component<ProgressProps, ProgressState> {
  constructor(props: ProgressProps) {
    super(props);
    this.state = {
      completed: 12.01,
    };
  }
  render() {
    return (
      <div className="progress">
        <h3 className="progress_title">
          <span>Acompanhe</span> as arrecadações
        </h3>
        <ProgressBar
          className="progress_wrapper"
          completed={this.state.completed}
          bgColor="linear-gradient(90deg, #B7F 2.25%, #5643E4 29.62%, #3C006A 62.37%, #0754C8 100%)"
          height="1rem"
          isLabelVisible={true}
          baseBgColor="#B8B8B8"
          borderRadius="0.5rem"
          labelClassName="progress_label"
        />
      </div>
    );
  }
}

export default Progress;
