import React, { Component } from "react";
import "./styles/index.scss";

interface KnowCardProps {
  firstText: string;
  secondText: string;
  number: string;
  reverse?: boolean;
  link?: string;
  flipCard?: boolean;
  backText?: string;
  backTitle?: string;
}

interface KnowCardState {}

class KnowCard extends Component<KnowCardProps, KnowCardState> {
  render() {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={this.props.link}
        className="knowCard"
      >
        <h2 className="knowCard__back_element">{this.props.number}</h2>
        <div
          className={
            "knowCard__container " +
            (this.props.flipCard === true ? "flip" : "")
          }
        >
          <div
            className={
              "knowCard__container__front " +
              (this.props.reverse === true ? "reversed" : "")
            }
          >
            <p className="knowCard__container__front__hashtag">#rumoasede</p>
            <h2 className="knowCard__container__front__text">
              {this.props.firstText}{" "}
            </h2>
            <h2 className="knowCard__container__front__text_highlighted">
              {this.props.secondText}
            </h2>
            <img
              className="knowCard__container__front__bg"
              src={"/images/" + this.props.number + "-bg.png"}
              alt={this.props.firstText}
            />
          </div>
          {this.props.flipCard ? (
            <div className="knowCard__container__back">
              <p className="knowCard__container__front__hashtag">#rumoasede</p>
              <b className="knowCard__container__back__title">
                {this.props.backTitle}
              </b>
              <p className="knowCard__container__back__text">
                {this.props.backText}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </a>
    );
  }
}

export default KnowCard;
