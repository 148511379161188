import React, { Component } from "react";
import "./styles/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

interface SlideArrowProps {
  onClick?: () => void;
  direction: string;
}

interface SlideArrowState {}

class SlideArrow extends Component<SlideArrowProps, SlideArrowState> {
  render() {
    return (
      <div
        className={"slider__arrow slider__arrow_" + this.props.direction}
        onClick={this.props.onClick}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
    );
  }
}

export default SlideArrow;
