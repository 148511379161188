import React, { Component } from "react";
import "./styles/index.scss";

interface TestimonyProps {
  videoId: string;
  title: string;
}

interface TestimonyState {}

class Testimony extends Component<TestimonyProps, TestimonyState> {
  render() {
    return (
      <div
        id={this.props.title}
        className="modal modal-xl fade"
        aria-labelledby={"testimony-" + this.props.title}
        aria-hidden="true"
      >
        <div className="modal-dialog bg-dark">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <iframe
              width="560"
              height="315"
              src={
                "https://www.youtube.com/embed/" +
                this.props.videoId +
                "?controls=0&amp;autoplay=0&amp;hd=1&amp;start=0&amp;rel=0"
              }
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimony;
