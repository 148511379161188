import React, { Component } from "react";
import "./styles/index.scss";
import Separator from "../separator";
import KnowCard from "../know-card";
import Slider from "react-slick";
import SlideArrow from "../slide-arrow";
import Progress from "../progress";

interface KnowProps {}

interface KnowState {}

class Know extends Component<KnowProps, KnowState> {
  render() {
    return (
      <section className="know">
        <div className="know__graphic">
          <Separator />
        </div>
        <div className="know__title">
          <h1 className="know__title_basic">Conheça Mais</h1>
          <h1 className="know__title_highlighted">Sobre nós</h1>
        </div>

        <Slider
          infinite={true}
          slidesToShow={4}
          dots={false}
          arrows={true}
          speed={500}
          className="know__cards"
          nextArrow={<SlideArrow direction="right" />}
          prevArrow={<SlideArrow direction="left" />}
          responsive={[
            {
              breakpoint: 920,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          swipeToSlide={true}
          touchMove={true}
        >
          <KnowCard
            link="https://cristovive.com.br/"
            firstText="Site"
            secondText="Oficial"
            number="1"
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/120798/fkarys"
            firstText="ministério de jovens"
            secondText="F.KARYS"
            number="2"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/120800/seeds"
            firstText="ministério infantil"
            secondText="SEEDS"
            number="3"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/125536/meg"
            firstText="ministério feminino"
            secondText="MEG"
            number="4"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/122891/cedrus"
            firstText="ministério Masculino"
            secondText="CEDRUS"
            number="5"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/120799/cristo-vive-care"
            firstText="assistência social"
            secondText="CRISTOVIVE CARE"
            number="6"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/118780/pactus"
            firstText="ministério de dança"
            secondText="CIA PACTUS"
            number="7"
            reverse
          />
          <KnowCard
            link="https://cristovive.com.br/pagina/121831/cristo-vive-music"
            firstText="Ministério de louvor"
            secondText="CRISTOVIVE MUSIC"
            number="8"
            reverse
          />
        </Slider>

        <div className="know__progress">
          <Progress />
        </div>
      </section>
    );
  }
}

export default Know;
