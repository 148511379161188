import React, { Component } from "react";
import "./styles/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface CardProps {
  price: string;
  checkoutUrl: string;
  icon: IconProp;
  multiplier: boolean;
  bonus: boolean;
  bonusText?: string;
}

interface CardState {}

class Card extends Component<CardProps, CardState> {
  render() {
    return (
      <div className="card">
        <FontAwesomeIcon icon={this.props.icon} size="3x" color="#fff" />
        <h1
          className={
            "card__text " +
            (this.props.multiplier === true ? "has-multiplier" : "")
          }
        >
          {this.props.price}
        </h1>
        <span className="card__separator"></span>
        <a className="card__button" href={this.props.checkoutUrl}>
          contribuir
        </a>
        {this.props.bonus === true ? (
          <div className="card__bonus">
            <h3 className="card__bonus_title">+Bônus</h3>
            <p className="card__bonus_text">{this.props.bonusText}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Card;
