import React, { Component } from "react";
import "./styles/index.scss";
import StickyNavbar from "react-sticky-navbar";

interface NavbarProps {}

interface NavbarState {}

class Navbar extends Component<NavbarProps, NavbarState> {
  render() {
    return (
      <StickyNavbar
        showOnTop={false}
        showOnScrollDown={true}
        showOnScrollUp={true}
      >
        <nav className="topNavbar">
          <div className="topNavbar__brand">
            <img
              className="topNavbar__brand_image"
              src="/images/Cristo-Vive---Branco 1.png"
              alt="brand"
            />
          </div>
          <button
            className="topNavbar__button"
            data-bs-toggle="modal"
            data-bs-target="#plans-modal"
          >
            Quero Doar
          </button>
        </nav>
      </StickyNavbar>
    );
  }
}

export default Navbar;
