import React, { Component } from "react";

import "./styles/index.scss";
import Progress from "../progress";
import Testimony from "../testimony";
// import config from "../../config";

interface HeroProps {}

interface HeroState {}

class Hero extends Component<HeroProps, HeroState> {
  render() {
    return (
      <section className="hero">
        <div className="hero__left-light"></div>
        <div className="hero__right-light"></div>
        <div className="hero__content">
          <h1 className="hero__content_title">Rumo à Sede</h1>
          <h2 className="hero__content_subtitle">
            Expandindo a nossa capacidade de anunciar o evangelho da graça.
          </h2>
          <p className="hero__content_text">
            Uma visão de futuro gloriosa e uma jornada de fé e conquista. É
            chegado o tempo de realizarmos nosso grande sonho, a construção da
            sede própria da Cristo Vive – São Paulo.
          </p>
          <button
            data-bs-toggle="modal"
            data-bs-target="#plans-modal"
            className="hero__content_button"
          >
            Quero Doar
          </button>
        </div>
        <div
          data-bs-toggle="modal"
          data-bs-target="#video0"
          className="hero__media"
        >
          <img
            className="hero__media_image"
            src="/images/hero-media.png"
            alt="hero-media"
          />

          <img
            className="hero__media_icon"
            src="/images/icons/play-icon.svg"
            alt="play"
          />
        </div>

        <Progress />

        <Testimony videoId="hSI-A57xO0o" title="video0" />

        <a
          className="hero__bottom_banner-box"
          href="https://drive.google.com/file/d/1BjDclY-iDbEYXetxSBz448XLFsxpFdMc/view?usp=drivesdk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="hero__bottom_banner"
            src="/images/Banner_Hotsite.png"
            alt="Catalogo Coleção Conquer"
          />
        </a>
      </section>
    );
  }
}

export default Hero;
