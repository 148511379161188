import React, { Component } from "react";
import "./styles/index.scss";

interface SeparatorProps {}

interface SeparatorState {}

class Separator extends Component<SeparatorProps, SeparatorState> {
  render() {
    return <div className="separator"></div>;
  }
}

export default Separator;
