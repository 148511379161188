import React, { Component } from "react";

import "./styles/index.scss";
// import config from "../../config";

interface GreetingsProps {}

interface GreetingsState {}

class Greetings extends Component<GreetingsProps, GreetingsState> {
  render() {
    return (
      <section className="greetings">
        <div className="greetings__left-light"></div>
        <div className="greetings__right-light"></div>
        <h1 className="greetings__title">Agradecemos pela sua generosidade!</h1>
        <iframe
          width="560"
          height="315"
          src={
            "https://www.youtube.com/embed/9B14PRfJg0M?controls=0&amp;autoplay=0&amp;hd=1&amp;start=0&amp;rel=0"
          }
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        <p className="greetings__text">
          Declaramos sobre a sua vida as mais ricas bÊnçãos e que essa sua
          doação dará muitos frutos para a glória de Deus.
        </p>
      </section>
    );
  }
}

export default Greetings;
