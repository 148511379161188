import React, { Component } from "react";
import "./styles/index.scss";
import Card from "../card";
import {
  faCreditCard,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";

interface PlansModalProps {}

interface PlansModalState {}

class PlansModal extends Component<PlansModalProps, PlansModalState> {
  render() {
    return (
      <div
        id="plans-modal"
        className="modal modal-xl fade"
        aria-labelledby="plansModal"
        aria-hidden="true"
      >
        <div className="modal-dialog bg-dark">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <h1 className="modal__title">
              <span>Escolha</span> uma das formas abaixo para a sua doação:
            </h1>
            <div className="modal__blocks">
              <div className="modal__first_block">
                <div className="modal__blocks__header">
                  PLANOS DE ASSINATURA mensais
                </div>
                <div className="modal__cards">
                  <Card
                    icon={faCreditCard}
                    multiplier={true}
                    checkoutUrl="https://pay.hotmart.com/A93777871F?checkoutMode=10"
                    price="R$29,90"
                    bonus={true}
                    bonusText="e-book de Estudo Bíblico exclusivO"
                  />

                  <Card
                    icon={faCreditCard}
                    multiplier={true}
                    checkoutUrl="https://pay.hotmart.com/T93777996J?checkoutMode=10"
                    price="R$59,90"
                    bonus={true}
                    bonusText="2 e-bookS de Estudo Bíblico exclusivOS"
                  />

                  <Card
                    icon={faCreditCard}
                    multiplier={true}
                    checkoutUrl="https://pay.hotmart.com/R93778065K?checkoutMode=10"
                    price="R$99,90"
                    bonus={true}
                    bonusText="4 e-bookS de Estudo Bíblico exclusivOS"
                  />
                </div>
              </div>
              <div className="modal__second_block">
                <div className="modal__blocks__header">DOAÇÃO ÚNICA</div>
                <Card
                  icon={faHandHoldingHeart}
                  multiplier={false}
                  checkoutUrl="https://pay.hotmart.com/X93778295V?checkoutMode=10"
                  price="Doação Única"
                  bonus={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlansModal;
